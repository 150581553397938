import { Guid } from '../../shared/guid';
// IMPORTANT: any changes to these classes needs to consider existing data saved in scp.Page ContentJson column

export class BaseContentJson {
  IsInPrimaryQuickNav = false;
  IsInSecondaryQuickNav = false;
  Description: string;

  constructor(json?: string) {
    if (json) {
      const data = JSON.parse(json);
      Object.assign(this, data);
    }
  }
}

export class HomepageContent extends BaseContentJson {
  HeroImages: IScpContentImage[];
}

export class AreaMapContent extends BaseContentJson {
  Categories: AreaMapPoiCategory[] = [];
  IsUsingSavedResults = false;
  ProductTypes: string[] = [];

  constructor(json?: string) {
    super();
    if (json) {
      const data = JSON.parse(json);
      Object.assign(this, data);
    }
  }
}

export class AreaMapPoiCategory {
  Id: string;
  Name: string;
  Keyword: string;
  ExcludedMarkers: AreaMapPoi[] = [];
  IncludedSavedResults: AreaMapPoi[] = [];
  IconSvgContent: string;
  IconId: number | string;
  MarkerColor: string;
  SearchRadius: number;

  constructor(data?: AreaMapPoiCategory) {
    Object.assign(this, data);

    if (!this.Id) this.Id = Guid.Generate();
  }
}

export class AreaMapPoi {
  Id: string;
  Name: string;
  Address?: string;
  City?: string;
  State?: string;
  Latitude?: number;
  Longitude?: number;
  Image?: IScpContentImage;
  IsEditable?: boolean;
  IconId?: number | string;
  SvgContent?: string;
}

export interface IScpContentImage {
  Filename: string;
  SortOrder: number;
}
