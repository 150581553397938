﻿<div class="pagination" *ngIf="pager.totalPages > 1">
    <div class="prev icon" (click)="pager.setIndex(pager.index - 1)" [ngClass]="{hide: pager.index === 0}">
        <svg id="arrow_left" data-name="arrow left" xmlns="http://www.w3.org/2000/svg" width="24" height="23.85" viewBox="0 0 24 23.85">
            <path d="M24,11.58H1.69L11.74.93A.5.5,0,0,0,11,.21L.15,11.72a.48.48,0,0,0-.15.36.48.48,0,0,0,.16.36L11,23.79a.54.54,0,0,0,.35.14.52.52,0,0,0,.36-.16.5.5,0,0,0,0-.71L1.7,12.58H24Z"
                transform="translate(0 -0.07)" fill="#fff" /></svg>
    </div>
    <div class="page-label" *ngIf="pager.totalPages > 0"><strong>{{pager.index + 1}}</strong> of {{pager.totalPages}}</div>
    <div class="next icon" (click)="pager.setIndex(pager.index + 1)" [ngClass]="{hide: pager.index === pager.totalPages - 1}">
        <svg id="arrow_right" data-name="arrow right" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M23.85,11.72,13,.21a.5.5,0,0,0-.7.72l10,10.65H0v1H22.3l-10,10.48a.5.5,0,0,0,0,.71.52.52,0,0,0,.36.16.54.54,0,0,0,.35-.14L23.84,12.44a.48.48,0,0,0,.16-.36A.48.48,0,0,0,23.85,11.72Z"
                transform="translate(0 -0.07)" fill="#fff" /></svg>
    </div>
</div>