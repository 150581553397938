export interface IPaginationObject<T> {
  onIndexChanged: (items: Array<T>) => void;
  displayedItems: T[];
  index: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;

  allItems: T[];

  setIndex: (index: number) => void;
}

export class PagedApiResponse<T> {
  CurrentPage: number;
  PageSize: number;
  TotalItemsCount: number;
  TotalPages: number;
  Items: T[];
}

// use when all items are available in memory
export class PaginationObject<T> implements IPaginationObject<T> {
  onIndexChanged: (items: Array<T>) => void;
  displayedItems: T[];
  index: number;
  totalPages: number;
  itemsPerPage: number;
  private _allItems: T[];

  constructor(itemsPerPage: number, items?: T[], indexChangeHandler?: (items: Array<T>) => void) {
    this.onIndexChanged = indexChangeHandler;
    this.itemsPerPage = itemsPerPage;
    this.index = 0;
    this._allItems = [];
    this.displayedItems = [];

    if (items) this.allItems = items;
  }

  get allItems(): T[] {
    return this._allItems;
  }

  set allItems(items: T[]) {
    this._allItems = items;
    if (items && items.length > 0) {
      this.totalPages = Math.ceil(this._allItems.length / this.itemsPerPage);
      this.setIndex(0);
    } else {
      this.totalPages = 0;
      this.displayedItems = [];
      if (this.onIndexChanged) this.onIndexChanged(this.displayedItems);
    }
  }

  get totalItems() {
    return this._allItems.length;
  }

  setIndex(index: number) {
    if (index > -1 && index < this.totalPages) {
      const start = index * this.itemsPerPage;
      const end =
        start + this.itemsPerPage < this._allItems.length
          ? start + this.itemsPerPage
          : this._allItems.length;

      this.displayedItems = this._allItems.slice(start, end);
      this.index = index;

      if (this.onIndexChanged) this.onIndexChanged(this.displayedItems);
    }
  }
}

// use when not all items are available in memory but must be retrieved each index change
export class DynamicPaginationObject<T> implements IPaginationObject<T> {
  onIndexChanged: (items: Array<T>) => void;
  displayedItems = new Array<T>();
  index = 0;
  private _allItems = new Array<T>();

  // should be set by consumer
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  requestPageItems: (pageNumber: number) => void;

  constructor(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
  }

  get allItems(): T[] {
    return this._allItems;
  }

  set allItems(items: T[]) {
    this._allItems = items;
    if (items && items.length > 0) {
      this.setIndex(0);
    } else {
      this.totalPages = 0;
      this.index = 0;
      this.displayedItems = [];
      if (this.onIndexChanged) this.onIndexChanged(this.displayedItems);
    }
  }

  addItems(items: T[]) {
    this._allItems.push(...items);
    this.setIndex(this.index);
  }

  setIndex(index: number) {
    if (index > -1 && index < this.totalPages) {
      const start = index * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      if (end > this._allItems.length) end = this._allItems.length;

      this.index = index;
      if (this._allItems[start]) {
        this.displayedItems = this._allItems.slice(start, end);
        if (this.onIndexChanged) this.onIndexChanged(this.displayedItems);
      } else {
        // assumption that pages are NOT 0 based like index
        this.requestPageItems(index + 1);
      }
    }
  }
}
