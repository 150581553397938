import { Component, Input, ViewEncapsulation } from '@angular/core';

import { IPaginationObject } from './pagination-object';

@Component({
    selector: 'paginator',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PaginationComponent {
    @Input()
    pager: IPaginationObject<any>;
}
