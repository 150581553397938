import { of as observableOf, Observable, of, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DictionaryPlus } from '../shared/data.structures';
import { Lot } from './lot';
import { Lookup } from '../shared/lookup';
import { LotVM } from '../content-editor/models/lot-vm';

@Injectable()
export class LotService {
  store = new DictionaryPlus<number, Lot>();
  hoodHistory = new Array<number>();
  lotsByCommunityStore = new DictionaryPlus<number, Lot[]>();
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient) {}

  getById(id: number, fromStage = false): Observable<Lot> {
    // check store first
    const lot = this.store[id];
    if (lot && !fromStage) {
      return observableOf(lot);
    } else {
      const base = fromStage ? this.stageApiBase : '';
      return this.http.get<Lot>(`${base}/api/lots/${id}`).pipe(
        map((l: Lot) => {
          if (!fromStage) this.store.addOrUpdate(l.LotId, l);
          return l;
        })
      );
    }
  }

  getLotsByNeighborhoodId(id: number, fromStage = false): Observable<Lot[]> {
    // hoodHistory tracks whether we have done an http get for all lots by neighborhood
    if (!fromStage && this.hoodHistory.indexOf(id) > -1) {
      return observableOf(this.store.values().filter(x => x.NeighborhoodId === id));
    } else {
      const base = fromStage ? this.stageApiBase : '';
      return this.http.get<Lot[]>(`${base}/api/lots/neighborhood/${id}`).pipe(
        map((lots: Lot[]) => {
          if (!fromStage) {
            lots.forEach(l => this.store.addOrUpdate(l.LotId, l));
            this.hoodHistory.push(id);
          }

          return lots;
        })
      );
    }
  }

  getLotsByCommunityId(id: number): Observable<Lot[]> {
    if (this.lotsByCommunityStore[id]) {
      return observableOf(this.lotsByCommunityStore[id]);
    } else {
      return this.http.get<Lot[]>(`/api/lots/community/${id}`).pipe(
        map((lots: Lot[]) => {
          lots.forEach(l => this.store.addOrUpdate(l.LotId, l));
          this.lotsByCommunityStore.addOrUpdate(id, lots);

          return lots;
        })
      );
    }
  }

  /** Optionally pass in the community id so that separate community store is kept up to date. */
  update(lot: Lot, communityId = 0, useStage = false): Observable<Lot> {
    // Lot.Statuses is not updated by to converge
    lot.Statuses = undefined;

    const base = useStage ? this.stageApiBase : '';
    return this.http.put<Lot>(`${base}/api/lots/${lot.LotId}`, lot).pipe(
      map((freshLot: Lot) => {
        this.store.addOrUpdate(freshLot.LotId, freshLot);

        // find community store and update
        if (communityId && !useStage && this.lotsByCommunityStore[communityId]) {
          const lots: Lot[] = this.lotsByCommunityStore[communityId];
          const index = lots.findIndex(x => x.LotId === freshLot.LotId);
          if (index > -1) lots[index] = freshLot;
        }

        return freshLot;
      })
    );
  }

  bulkUpdateLots(lots: LotVM[]) {
    for (const lot of lots) {
      lot.Statuses = undefined;
    }

    return firstValueFrom(this.http.put<void>(`${this.stageApiBase}/api/lots`, lots));
  }
}
