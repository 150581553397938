import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { IElevation, IMedia, MediaType } from '@ml/common';
import { FileSystemService } from '../file-system.service';
import { ImageVM } from '../image-upload/image-upload.component';
import { Lookup } from '../lookup';
import { ToastService } from '../toast/toast.service';
@Component({
  selector: 'media-editor',
  templateUrl: './media-editor.component.html',
  styleUrls: ['./media-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaEditorComponent implements OnChanges {
  @Input() media: Array<IMediaEditor>;
  @Input() baseUrl: string;
  @Input() config = new MediaEditorConfig();
  @Input() allowImageDownload: boolean;
  @Input() downloadTooltipText: string;
  @Output() mediaChange = new EventEmitter<Array<IMediaEditor>>();
  mediaVMs = new Array<MediaVM>();
  mediaItem: MediaVM;
  editEnabled = false;
  editIndex: number;
  mediaType = MediaType;
  previewItem: MediaVM;
  hasMedia = false;
  maxFileSizeMb = 10;
  primaryCheckboxDisabled = false;
  // downloadTooltipText = `Please note that you are downloading the latest elevation that has been linked to this floorplan.
  //                         Please contact your Account Manager if a more recent version is needed.`;

  constructor(
    private toaster: ToastService,
    private cdr: ChangeDetectorRef,
    private fileService: FileSystemService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.mediaItem = new MediaVM(this.baseUrl);
    this.mediaVMs = this.media.map(x => new MediaVM(this.baseUrl, x));
    if (this.mediaVMs.filter(x => !x.MarkedForDeletion).length === 1) {
      this.primaryCheckboxDisabled = true;
    } else {
      this.primaryCheckboxDisabled = false;
    }
    this.updateHasMedia();
  }

  updateHasMedia() {
    this.hasMedia = !!this.mediaVMs.filter(m => !m.MarkedForDeletion).length;
  }

  emitUpdatedMedia() {
    this.mediaChange.emit(this.mediaVMs.map(m => m.mapToEntity()));
  }

  addMedia() {
    if (
      (this.isFileMediaType(this.mediaItem) &&
        !this.mediaItem.ImageFilename &&
        !this.mediaItem.FileToUpload) ||
      (!this.isFileMediaType(this.mediaItem) && !this.mediaItem.ExternalUrl)
    ) {
      this.toaster.showError('Missing Fields', 'Please complete all required fields');
      return;
    }

    this.mediaItem.NeedsSave = true;

    if (this.config.IsPrimaryCheckbox && this.mediaVMs.length < 1) {
      this.mediaItem.IsPrimary = true;
    }

    if (!this.editEnabled) {
      this.mediaVMs.push(this.mediaItem);
    } else {
      this.editEnabled = false;
    }
    this.mediaItem = new MediaVM(this.baseUrl);
    this.emitUpdatedMedia();
    this.cdr.detectChanges();
  }

  isFileMediaType(media: MediaVM) {
    return (
      media.MediaType === MediaType.Static ||
      media.MediaType === MediaType.VRTour ||
      media.MediaType === MediaType.Image3D ||
      media.MediaType === MediaType.Unspecified
    );
  }

  editMedia(index: number) {
    this.editEnabled = true;
    this.editIndex = index;
    this.mediaItem = this.mediaVMs[index];
  }

  deleteMedia(index: number) {
    const media = this.mediaVMs[index];
    if (media === this.mediaItem) {
      this.mediaItem = new MediaVM(this.baseUrl);
      this.editEnabled = false;
    }
    if (!!media.Id) {
      media.MarkedForDeletion = true;

      media.IsPrimary = false;
    } else {
      this.mediaVMs.splice(index, 1);
    }

    if (this.config.IsPrimaryCheckbox) {
      this.checkAndHandlePrimaryImage();
    }
    this.emitUpdatedMedia();
    this.updateHasMedia();
  }

  drop(event: CdkDragDrop<MediaVM[]>) {
    if (!this.editEnabled) {
      moveItemInArray(this.mediaVMs, event.previousIndex, event.currentIndex);
      this.emitUpdatedMedia();
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  handleImagesChange(images: ImageVM[], media: MediaVM) {
    const image = images[0];
    media.FileToUpload = image.File;
    media.ImageVM = image;
    media.ImageFilename = image.File.name;
    if (!media.Title) media.Title = image.File.name;
  }

  handleBulkUploadChange(files: File[]) {
    if (files.length === 1) {
      const file = files[0];
      this.mediaItem.FileToUpload = file;
      this.mediaItem.ImageVM = {
        ...new ImageVM(),
        File: file,
        Filename: file.name,
        PreviewUrl: URL.createObjectURL(file)
      };
      this.mediaItem.ImageFilename = file.name;
      this.mediaItem.NeedsSave = true;
      if (!this.isFileMediaType(this.mediaItem)) this.mediaItem.MediaType = MediaType.Static;
      if (!this.mediaItem.Title) this.mediaItem.Title = file.name;
      return;
    }

    for (const file of files) {
      const media = new MediaVM(this.baseUrl);
      media.FileToUpload = file;
      media.ImageVM = {
        ...new ImageVM(),
        File: file,
        Filename: file.name,
        PreviewUrl: URL.createObjectURL(file)
      };
      media.ImageFilename = file.name;
      media.NeedsSave = true;
      media.MediaType = !this.isFileMediaType(this.mediaItem)
        ? MediaType.Static
        : this.mediaItem.MediaType;
      media.Title = file.name;
      this.mediaVMs.push(media);
    }
    this.checkAndHandlePrimaryImage();
    this.emitUpdatedMedia();
  }

  togglePreview(media?: MediaVM) {
    if (!this.previewItem && (media.ImageVM?.PreviewUrl || media.ExternalUrl)) {
      this.previewItem = media;
    } else {
      this.previewItem = null;
    }
  }

  checkAndHandlePrimaryImage(effectiveIndex?: number) {
    const needsDefault = !this.mediaVMs.filter(x => !x.MarkedForDeletion).some(x => x.IsPrimary);

    if (needsDefault) {
      const firstAvailable = this.mediaVMs.find(
        (m, idx) => !m.MarkedForDeletion && !m.IsPrimary && idx !== effectiveIndex
      );
      if (firstAvailable) {
        firstAvailable.IsPrimary = true;
        firstAvailable.NeedsSave = true;
      }
    }
  }

  handlePrimaryImageSelection(evt, media: MediaVM) {
    let effectiveIndex;
    this.mediaVMs = this.mediaVMs.map((m, idx) => {
      if (m.ImageFilename === media.ImageFilename) {
        effectiveIndex = idx;
        m.IsPrimary = evt;
      } else {
        m.IsPrimary = false;
      }
      m.NeedsSave = true;
      return m;
    });
    this.checkAndHandlePrimaryImage(effectiveIndex);
    this.emitUpdatedMedia();
  }

  async downloadMedia(media: MediaVM) {
    this.toaster.showLoading();
    this.fileService
      .downloadImageFromUrl(media.ImageVM.PreviewUrl, media.ImageFilename)
      .then(() => {
        this.toaster.hideLoading();
      })
      .catch(err => {
        this.toaster.showError('Download Image Error', 'There was an err downloading the image');
        console.log(err);
      });
  }
}

export interface IMediaEditor {
  Id: number;
  Title: string;
  ImageFilename: string;
  MediaType: MediaType;
  MarkedForDeletion: boolean;
  FileToUpload: File;
  ClientUniqueIdentifier: string;
  ExternalUrl: string;
  AllowDelete: boolean;
  Cost: number;
  FloorId?: number;
  IsPrimary: boolean;
  NeedsSave: boolean;
}

export class MediaVM implements IMediaEditor {
  Id: number;
  Title: string;
  ImageFilename: string;
  MediaType = MediaType.Static;
  MarkedForDeletion: boolean;
  FileToUpload: File;
  ClientUniqueIdentifier: string;
  ExternalUrl: string;
  AllowDelete = true;
  Cost: number;
  FloorId?: number;
  IsPrimary: boolean;

  ImageVM: ImageVM;
  NeedsSave = false;

  constructor(baseUrl: string, data?: IMediaEditor) {
    Object.assign(this, data);

    if (this.MediaType === MediaType.VRTour) baseUrl += '/vrtours';

    if (this.ImageFilename) {
      this.ImageVM = new ImageVM({
        File: null,
        Filename: this.ImageFilename,
        MarkedForDeletion: false,
        PreviewUrl: `${Lookup.ApiStageFsProductDataEndpoint}${baseUrl}/${this.ImageFilename}`
      });
    }
  }

  mapToEntity(): IMediaEditor {
    return {
      Id: this.Id,
      Title: this.Title,
      ImageFilename: this.ImageFilename,
      MediaType: this.MediaType,
      MarkedForDeletion: this.MarkedForDeletion,
      FileToUpload: this.FileToUpload,
      ClientUniqueIdentifier: this.ClientUniqueIdentifier,
      ExternalUrl: this.ExternalUrl,
      AllowDelete: this.AllowDelete,
      Cost: this.Cost,
      FloorId: this.FloorId,
      IsPrimary: this.IsPrimary,
      NeedsSave: this.NeedsSave
    } as IMediaEditor;
  }

  static Hydrate(item: IMedia | IElevation, baseUrl: string): MediaVM {
    if (item.hasOwnProperty('MediaId')) return MediaVM.HydrateFromMedia(item as IMedia, baseUrl);
    else return MediaVM.HydrateFromElevation(item as IElevation, baseUrl);
  }

  static HydrateFromMedia(media: IMedia, baseUrl: string): MediaVM {
    if (media.MediaType === MediaType.VRTour) baseUrl += '/vrtours';
    const m = {} as IMediaEditor;
    Object.assign(m, media);
    m.Id = media.MediaId;
    m.AllowDelete = !media.HotspotIds.length;

    return new MediaVM(baseUrl, m);
  }

  static HydrateFromElevation(elevation: IElevation, baseUrl: string): MediaVM {
    const e = {} as IMediaEditor;
    Object.assign(e, elevation);
    e.Id = elevation.ElevationId;

    return new MediaVM(baseUrl, e);
  }
}

export class MediaEditorConfig {
  OnlyImages?: boolean = false;
  ShowCUIDField?: boolean = false;
  ShowCost?: boolean = false;
  IsReadOnly?: boolean = false;
  IsPrimaryCheckbox?: boolean = false;
}
